const route= {
  ALL_SERIES: "all-series",
  CONTACT: "contact",
  HELP: "help",
  NEWSLETTER: "newsletter",
  ABOUT: "about",
  HISTORY: "history",
  LINK: "links",
  COOKIES: "cookies",
};

export {route}